* {
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}
.loader {
  border-top: 2px solid #01124A;
  border-radius: 55%;
  display: inline-block;
  width: 34px;
  height: 34px;
  animation: spin 1.5s ease-in infinite;
  vertical-align: top;
  margin-right: 20px;
  margin-top: 4px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading-bkg {
  z-index: 9999;
  vertical-align: top;
  color: #000;
  font-size: 36px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  letter-spacing: 6px;
  height: 102%;
  width: 102%;
  padding-top: 18%;
  background: rgba(255,255,255,0.7);
}
canvas {
  visibility: hidden;
  height: 0px;
  width: 0px;
}
.App {
}
.button {
  background: #01124A;
  color:#fff;
  padding:10px 30px;
  text-align: center;
  max-width:200px;
  margin:20px auto;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.4);

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.button:active {
  box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
  background: rgb(17, 46, 145);
}
#head {
  text-align: center;
  background:#01124A;
  top:0px;
  left:0px;
  width:100%;
  padding:10px 0px;
  height:126px;
}
#body {
  max-width:1310px;
  margin:0 auto;
  text-align: center;
  padding:50px 0px;
  white-space: nowrap;
}
.form {
  position: relative;
  display: inline-block;
  width:50%;
  text-align: left;
  margin:20px 0px;
  white-space: initial;
}
.form ul, .form li {
  padding:0px;
  margin:0px;
  list-style: none;
}
.form ul {
  padding:0px 0px 20px;
  max-width:364px;
  margin:0 auto;
}
.form li {
  margin:10px 0px;
}
.form input, .form select, .form textarea {
  width:200px;
  padding:5px;
}
.form textarea {
  min-height:100px;
}
.form label{
  margin-left:10px;
}
.form select {
  margin:20px 0px
}
.pdf {
  display: inline-block;
  vertical-align: top;
  width:50%;
  position: relative;
  transform-origin: left top;
}
.record-wrap {
  text-align: left;
  width:595px;
  display: block;
}
.record-wrap img {
  position: absolute;
  top:0px;
  left:0px;
  z-index: -1;
}
#footer {
  background:#01124A;
  color:#fff;
  text-align:center;
  width:100%;
  padding:20px 0px;
}
#footer a:visited {
  color:#fff
}

@media screen and (max-width:1310px) {
  .pdf {    
    width:595px;
  }
  .form {
    width: 377px;
    padding: 0px 10px;
  }
}
@media screen and (max-width:700px) {
  body {
    white-space: wrap;
  }
  .pdf,.form {
  display: block; 
  margin: 0 auto;
  }
}
@media screen and (max-width:690px) {
  .form {
    max-width:380px;
  }
  .form ul {
    max-width:100%;
  }
}
@media screen and (max-width:610px) {
  .pdf,.record-wrap {
    width:100%;
  }
}
@media screen and (max-width:380px) {
  .form, .form ul {
    max-width:100%;
  }
}